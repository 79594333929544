import React, { useContext } from 'react';
import CollapsibleDetailsSection from '../DetailsSection/CollapsibleDetailsSection';
import { BDPContext } from '../MainContent/BoatDetailsPage';

const BDPDetailsSection = ({
  isWorking, listing, myboats, cookies, url, detailsState,
  adParams, onClickRequestInformation, breakpoint,
  displayModalPreQualified, shouldShowFinanceSummary
}) => {

  const {tridentValues, isLeadSubmitted, boatLoansSection} = useContext(BDPContext);
  const {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate} = tridentValues;

  return (
    <CollapsibleDetailsSection
      url={url}
      myboats={myboats}
      cookies={cookies}
      listing={listing}
      adParams={adParams}
      isLoading={isWorking}
      breakpoint={breakpoint}
      isLeadSubmitted={isLeadSubmitted}
      boatLoansSection={boatLoansSection}
      tridentLoanAmount={tridentLoanAmount}
      tridentTeaserRate={tridentTeaserRate}
      tridentTermInMonths={tridentTermInMonths}
      showModalPreQualified={displayModalPreQualified}
      onClickRequestInformation={onClickRequestInformation}
      openPaymentCounter={detailsState.openPaymentCounter}
      openPaymentSection={detailsState.openPaymentSection}
      openBoatLoansCounter={detailsState.openBoatLoansCounter}
      openBoatLoansSection={detailsState.openBoatLoansSection}
      shouldShowFinanceSummary={shouldShowFinanceSummary}
      isMobile={breakpoint !== 'desktop'}
    />
  );
};

export default BDPDetailsSection;
