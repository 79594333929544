import React, { useContext } from 'react';
import BoatSummary from '../../../components/BoatSummary';
import { BDPContext } from '../MainContent/BoatDetailsPage';

const BDPBoatSummaryComponent = ({
  breakpoint, isWorking,
  summaryHandlers, handleOpenContactForm}) => {

  const {bannerText, tridentValues,
    listing} = useContext(BDPContext);
  const {
    handleSummaryPaymentLinkClicked,
    handleSummaryLocationLinkClicked
  } = summaryHandlers;
  const {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate} = tridentValues;

  return (
    <div className="boat-summary" data-testid="boat-summary">
      <BoatSummary
        breakpoint={breakpoint}
        isLoading={isWorking}
        listing={listing}
        onClickPaymentLink={handleSummaryPaymentLinkClicked}
        onClickLocationLink={handleSummaryLocationLinkClicked}
        onClickPaymentTotal={handleOpenContactForm}
        bannerText={bannerText}
        tridentTermInMonths={tridentTermInMonths}
        tridentLoanAmount={tridentLoanAmount}
        tridentTeaserRate={tridentTeaserRate}
      />
    </div>
  );
};

const BDPBoatSummary = (props) => {
  return props?.show ? <BDPBoatSummaryComponent {...props}  /> : null;
};
export default BDPBoatSummary;
