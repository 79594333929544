import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withABTest } from '../../../server/abTest/withABTest';
import { bindActionCreators } from 'redux';
import { addLead, trackContactFormOpen, trackPhoneCall, trackPhoneNumberReveal } from '../../../store/actions/dataLayer';
import { isFSBOContact } from '../../../utils/listingHelper';
import './styles.css';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import { setSessionItem } from '../../../utils/storage';
import { makePrivateSummaryTrackingEvent } from '../../../utils/trident';

class ContactGroup extends PureComponent {
  state = {
    revealPhoneNumber: false
  }
  render() {
    const contact = get(this.props, 'listing.contact');
    const phoneNumber = get(contact, 'phone');
    const dealerName = get(this.props, 'listing.owner.rootName') || get(this.props, 'listing.owner.name');
    const oemListing = get(this.props, 'listing.oemDetails', false);
    const showDealer = get(oemListing, 'oemDealerLocator');
    const brandName =  showDealer ? 'Find a Dealer Near You' : 'Request Info';
    const isUserLoginExp = buyerAccountLoginExperiment.isActive(this.props.abTestConfiguration, this.props.listing);
    const actionLabel = isUserLoginExp ? 'Contact Seller' : 'Request Info';
    if (isFSBOContact(contact)) {
      return <div className={classnames('contact-group', { 'show-icons': this.props.showIcons })}>
        <div className="offered-by" data-e2e="offered-by">Offered By: <span className="dealer-name">{dealerName}</span></div>
        <div className="contact-buttons" data-e2e="contact-buttons">
          <Action className="contact-button contact-button-fsbo" data-e2e="request-info-button" onClick={this.handleContactOpen} label={actionLabel} size="large" />
        </div>
      </div>;
    }
    return <div className={classnames('contact-group', { 'show-icons': this.props.showIcons })}>
      <div className="offered-by" data-e2e="offered-by">Offered By: <span className="dealer-name">{dealerName}</span></div>
      <div className="contact-buttons" data-e2e="contact-buttons">
        <Action
          className={classnames('contact-button', { 'contact-button-oem': oemListing })}
          data-testid="request-info-btn"
          onClick={this.handleContactOpen}
          label={brandName}
          data-e2e="request-info-button"
        />
        {!oemListing && (
          <>
            <Action data-e2e="call-button" variant="secondary" className={classnames('call-button', {'hide': this.state.revealPhoneNumber})} as="a" href={`tel:${phoneNumber}`} onClick={this.handlePhoneCall} label="Call" />
            <div className={classnames('phone-number', {'hide': !this.state.revealPhoneNumber})} data-e2e={classnames('phone-number', {'hide': !this.state.revealPhoneNumber})}>Call: <span>{phoneNumber}</span></div>
          </>
        )}
      </div>
    </div>;
  }

  handleContactOpen = () => {
    const oemListing = get(this.props, 'listing.oemDetails', {});
    if (oemListing.oemDealerLocator && this.props.openModalDealerLocator) {
      this.props.openModalDealerLocator();
    } else {
      const isUserLoginExp = buyerAccountLoginExperiment.isActive(this.props.abTestConfiguration, this.props.listing);
      const isFSBO = isFSBOContact(get(this.props, 'listing.contact'));
      if (isUserLoginExp && !this.props.userEmail && isFSBO) {
        const contactTracking = makePrivateSummaryTrackingEvent('contact seller carousel gallery', 'click');
        window.dataLayer.push(contactTracking);

        // Set a session storage item to handle the case when the user performs a
        // social login and a redirect occurs.
        const sessionKey = `displayContactSeller${window.location.pathname}`;
        setSessionItem(sessionKey, true);

        this.props.userRef?.current?.onOpenModal();
      } else {
        this.props.onOpenContact();

        if ((this.props.isOpening === undefined || this.props.isOpening) && !this.props.showDealers) {
          this.props.trackContactFormOpen();
        }
      }
    }
  }

  handlePhoneCall = () => {
    if (this.props.device !== 'desktop'){
      this.props.trackPhoneCall();
      this.props.addLead(get(this.props, 'listing.id'), 'phone lead');
    } else {
      this.props.trackPhoneNumberReveal();
      this.props.addLead(get(this.props, 'listing.id'), 'phone lead');
      this.setState({revealPhoneNumber: true});
    }
  }
}

export default withABTest(connect(
  state => ({
    userRef: get(state.app, 'userRef', {}),
    userEmail: get(state.app, 'userEmail')
  }),
  dispatch => bindActionCreators({
    trackContactFormOpen,
    trackPhoneCall,
    trackPhoneNumberReveal,
    addLead
  }, dispatch)
)(ContactGroup));
